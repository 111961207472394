exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.marketing-block-banner_flex-wrapper_1YSeT {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n\n    /*\n        Make 2 columns:\n        a) for the blocks + workspace panes, and\n        b) for combined stage menu + stage + sprite/stage selectors\n    */\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: row;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    height: 15%;\n\n    /*\n        Stop scrollbar popping in and out from scratch-blocks border issue\n        https://github.com/LLK/scratch-gui/issues/318\n    */\n    overflow-y: hidden;\n\n    background-color: #00af41;\n}\n\n.marketing-block-banner_pict-06-cell_c_P8C{\n\n   /* background-image: url('/static/robbo_assets/scratch_ru/pict-01.png'); */\n\n    height: 4%;\n    width: 8%;\n\n    margin-left: 30px;\n    margin-top: 60px;\n\n}\n\n.marketing-block-banner_pict-07-cell_37k5n{\n\n  /*  background-image: url('/static/robbo_assets/scratch_ru/pict-02.png'); */\n\n    height: 4%;\n    width: 2%;\n\n    margin-left: 40px;\n    margin-top: 60px;\n\n}\n\n.marketing-block-banner_pict-08-cell_29t5b{\n\n  \n\n    height: 4%;\n    width: 2%;\n   \n    margin-left: 20px;\n    margin-top: 60px;\n\n}\n\n.marketing-block-banner_pict-09-cell_3GLR4{\n\n   \n\n    height: 4%;\n    width: 2%;\n\n    margin-left: 40px;\n    margin-top: 60px;\n\n}\n\n.marketing-block-banner_pict-10-cell__BtUW{\n\n   \n\n    height: 4%;\n    width: 2%;\n\n    margin-left: 70px;\n    margin-top: 60px;\n\n}\n\n.marketing-block-banner_text-cell__UGcm{\n\n    height: 4%;\n    width: 40%;\n\n    margin-left: 15px;\n    margin-top: 60px;\n\n    color:white;\n\n}\n\n.marketing-block-banner_text-cell__UGcm a:link {\n\n    color:white;\n}\n\n.marketing-block-banner_text-cell__UGcm a:visited {\n\n    color:white;\n}\n\n", ""]);

// exports
exports.locals = {
	"flex-wrapper": "marketing-block-banner_flex-wrapper_1YSeT",
	"flexWrapper": "marketing-block-banner_flex-wrapper_1YSeT",
	"pict-06-cell": "marketing-block-banner_pict-06-cell_c_P8C",
	"pict06Cell": "marketing-block-banner_pict-06-cell_c_P8C",
	"pict-07-cell": "marketing-block-banner_pict-07-cell_37k5n",
	"pict07Cell": "marketing-block-banner_pict-07-cell_37k5n",
	"pict-08-cell": "marketing-block-banner_pict-08-cell_29t5b",
	"pict08Cell": "marketing-block-banner_pict-08-cell_29t5b",
	"pict-09-cell": "marketing-block-banner_pict-09-cell_3GLR4",
	"pict09Cell": "marketing-block-banner_pict-09-cell_3GLR4",
	"pict-10-cell": "marketing-block-banner_pict-10-cell__BtUW",
	"pict10Cell": "marketing-block-banner_pict-10-cell__BtUW",
	"text-cell": "marketing-block-banner_text-cell__UGcm",
	"textCell": "marketing-block-banner_text-cell__UGcm"
};