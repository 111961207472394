
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './marketing-block-banner.css';

import Box from '../box/box.jsx';


const MarketingBlockBanner = props => (
    
      
        <Box className={styles.flexWrapper}>

            
                <Box className={styles.pict06Cell}>
                    <a href='https://robbo.ru'> <img src='/static/robbo_assets/scratch_ru/pict-01.png'/>  </a>
                </Box>

                <Box  className={styles.pict10Cell}>
                     <a href='https://innoclass.ru/'> <img src='/static/robbo_assets/scratch_ru/pict-05.png'/>  </a>
                 </Box>
                <Box  className={styles.textCell}>
                <a href='https://innoclass.ru/'>
                        Модернизация урока "Технология" <br/>
                       <b> Инженерный инновационный РОББО Класс </b>
                </a>
                </Box>
            
                <Box  className={styles.pict09Cell}>
                    <a href='https://robbo.ru/olymp/'> <img src='/static/robbo_assets/scratch_ru/pict-04.png'/>  </a>
                </Box>
                <Box  className={styles.textCell}>
                    <a href='https://robbo.ru/olymp/'>   
                        Scratch-Олимпиада <br/>
                        <b> Российский национальный отборочный этап по креативному программированию </b>
                    </a>
                </Box>

           {/* <Box  className={styles.pict08Cell}>
                 <a href='https://lms2.robbo.world/courses/course-v1:ROBBO+C4+November/about'> <img src='/static/robbo_assets/scratch_ru/pict-03.png'/>  </a>
            </Box>
            <Box  className={styles.textCell}>
                <a href='https://lms2.robbo.world/courses/course-v1:ROBBO+C4+November/about'>  
                    Для педагогов <br/>
                    <b>  Курс обучения робототехнике </b>
                </a>
            </Box>
           
            <Box  className={styles.pict07Cell}>
                <a href='https://lms2.robbo.world/courses/course-v1:Robbo+AC002+June/about'> <img src='/static/robbo_assets/scratch_ru/pict-02.png'/>  </a>
            </Box>
            <Box  className={styles.textCell}>
                <a href='https://lms2.robbo.world/courses/course-v1:Robbo+AC002+June/about'>  
                    Для учащихся <br/>
                    <b> Секреты Скретч </b>
                </a>
            </Box> */}
           
            
           
        </Box>

        

);
export default MarketingBlockBanner;